import {
  MixedLocale,
  StringLocale,
  NumberLocale,
  DateLocale,
  ObjectLocale,
  ArrayLocale,
  BooleanLocale
} from 'yup/lib/locale';

export const mixed: Required<MixedLocale> = {
  default: 'Ce champ est invalide',
  required: 'Ce champ est obligatoire',
  oneOf: '${path} must be one of the following values: ${values}',
  notOneOf: '${path} must not be one of the following values: ${values}',
  notType: '',
  // notType: ({ path, type, value, originalValue }) => {
  //   const isCast = originalValue != null && originalValue !== value;
  //   let msg =
  //     `${path} must be a \`${type}\` type, ` +
  //     `but the final value was: \`${printValue(value, true)}\`` +
  //     (isCast ? ` (cast from the value \`${printValue(originalValue, true)}\`).` : '.');
  //
  //   if (value === null) {
  //     msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
  //   }
  //
  //   return msg;
  // },
  defined: '${path} must be defined'
};

export const string: Required<StringLocale> = {
  length: 'Ce champ doit faire exactement ${length} caractères',
  min: 'Ce champ doit faire au moins ${min} caractères',
  max: 'Ce champ doit faire au plus ${max} caractères',
  matches: '${path} must match the following: "${regex}"',
  email: 'Ce champ doit être une adresse mail valide',
  url: '${path} must be a valid URL',
  uuid: '${path} must be a valid UUID',
  trim: '${path} must be a trimmed string',
  lowercase: '${path} must be a lowercase string',
  uppercase: '${path} must be a upper case string'
};

export const number: Required<NumberLocale> = {
  min: '${path} must be greater than or equal to ${min}',
  max: '${path} must be less than or equal to ${max}',
  lessThan: '${path} must be less than ${less}',
  moreThan: '${path} must be greater than ${more}',
  positive: '${path} must be a positive number',
  negative: '${path} must be a negative number',
  integer: '${path} must be an integer'
};

export const date: Required<DateLocale> = {
  min: '${path} field must be later than ${min}',
  max: '${path} field must be at earlier than ${max}'
};

export const boolean: BooleanLocale = {
  isValue: '${path} field must be ${value}'
};

export const object: Required<ObjectLocale> = {
  noUnknown: '${path} field has unspecified keys: ${unknown}'
};

export const array: Required<ArrayLocale> = {
  min: '${path} field must have at least ${min} items',
  max: '${path} field must have less than or equal to ${max} items',
  length: '${path} must be have ${length} items'
};

export default Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean
});
